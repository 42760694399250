<template>
  <footer>
    <div class="columns">
      <div class="column">
        <a href="/workspace">I-ASSISTANT</a>
        <p>ИНН 524404422386</p>
        <p>ОГРНИП 321527500035571</p>
      </div>
      <div class="column">
        <a href="/doc/">Главная</a>
        <a href="/doc/uses_conditions.pdf">Условия использования</a>
        <a href="/#price">Тарифы и цены</a>
      </div>
      <div class="column">
        <a href="/doc/agree.pdf"> Пользовательское соглашение</a>
        <a href="/doc/privacy_policy.pdf"> Политика конфиденциальности</a>
      </div>
      <div class="column">
        <h6>Контакты</h6>
        <a href="tel:+78312199846">8 831 219 98 46</a>
        <a href="mailto:support@i-assistant.ru">support@i-assistant.ru</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AuthFooter',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

footer {
  background-color: $authFooter;
  grid-area: footer;
  color: $white;
  padding: 0.75rem 20px;

  a {
    color: $white;
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h6 {
    color: $white;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: #ccd9e4;
  }
}
</style>
