<template>
  <main>
    <div class="auth-layout">
      <slot />
    </div>
    <AuthFooter />
  </main>
</template>

<script>
import AuthFooter from '@/components/app/AuthFooter';

export default {
  name: 'AuthLayout',
  components: { AuthFooter },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

main {
  background-color: #17a2b7;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 20px;
  grid-template-areas: 'content' 'footer';

  .auth-layout {
    width: 100%;
    height: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #17a2b7;
    grid-area: content;
    padding: 80px 0 20px;
  }
}
</style>
